@import "../../styles/Shared/fonts.css";

.Container-Login {
  margin-left: 0rem;
  margin-right: 0rem;
  text-align: center;
  padding: 0rem;
  margin-bottom: 20%;

}

.Div-Center {
  text-align: center;
}

.Div-Login {
  text-align: justify;
  padding: 1rem;
}

.Error {
  font-size: 1.2rem;
  font-family: "LatoSemiBold";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(226, 86, 86);
  width: 95%;
}

.Input-Login {
  margin-top: 0.5rem;
  border-color: #9e9e9e;
  border-style: solid;
  border-image: none;
  border-width: 0 0 2px;
  color: rgb(112, 110, 110);
  padding-left: 0.7rem;
  width: 100%;
  background-color: #EFEFEF;
}

.Input-Login:focus {
  border-color: #84ac34;
  outline: thick;
}

.Load-Login {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 50%;
  z-index: 1000;
}

.Logo-Login {
  margin-top: 20%;
  margin-bottom: 2rem;
  cursor: pointer;
  background-color: #EFEFEF;
}

.Submit-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1.2rem;
  color: #fff;
}

.Title-Form {
  font-size: 1.7rem;
  font-family: "GothamRoundedBold";
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  color: rgb(68, 68, 68);
}

.Text-Login {
  font-size: 1.2rem;
  font-family: "Gotham";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 99%;
}

.Text-Options {
  font-size: 1rem;
  font-family: "LatoSemiBold";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 95%;
  cursor: pointer;
}

.Text-Options:hover {
  color: #84ac34;
}
