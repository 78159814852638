.Body-Answers-Symptoms {
    margin-bottom: 34rem;
}


.Container-Card {
    border-style: solid;
    border-width: 0.1rem;
    border-color: #99999973;
    border-radius: 13px;
    background-color: white;
    padding: 1rem;
    overflow: hidden;
    margin: 1rem;
  }
  
  .Container-Card:hover {
    box-shadow: 0 1px 3px 1px #9e9e9e;
  }

  .Error-Answers {
    font-family: "Gotham";
    font-size: 1rem;
    margin-top: 1rem;
    color: #9e9e9e;
  }

  .List {
    margin-left: 0.7rem;
  }
  
  .Text-Answer {
      
    font-family: "LatoMedium";
    font-size: 1rem;
  }

.Title-Answer {
    font-family: "LatoSemiBold";
    font-size: 1.05rem;
}