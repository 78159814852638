@import "../../styles/Shared/fonts.css";

.Container-Register {
  margin-left: 0rem;
  margin-right: 0rem;
  text-align: center;
  padding: 0rem;
  margin-bottom: 20%;
}

.Container-Box {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 3rem;
}

.Container-Box:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Dropdown-Countries {
  border: none;
  background-color: #ffffff;
  font-size: 1rem;
  font-family: "LatoSemiBold";
  color: rgb(112, 110, 110);
}

.Dropdown-Countries:hover {
  border: none;
  background-color: #ffffff;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
}

.Error {
  font-size: 1.2rem;
  font-family: "Gotham";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(226, 86, 86);
  width: 95%;
}

.Input-Text {
  margin-top: 0.5rem;
  border-color: #9e9e9e;
  border-style: solid;
  border-image: none;
  border-width: 0 0 2px;
  color: rgb(112, 110, 110);
  padding-left: 0.7rem;
  width: 100%;
  font-size: 1rem;
  font-family: "LatoSemiBold";
}

.Input-Text:focus {
  border-color: #84ac34;
  outline: thick;
}

.Item-Countries {
  font-size: 1rem;
  font-family: "LatoSemiBold";
  color: rgb(112, 110, 110);
}

.Item-Countries:active {
  background-color: #9e9e9e90;
}

.Logo-Register {
  margin-top: 20%;
  margin-bottom: 2rem;
  cursor: pointer;
  max-width: 300px;
}

.Register-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1.2rem;
  color: #fff;
  max-width: 240px;
}

.Text-Form-Register {
  font-size: 1.05rem;
  font-family: "LatoSemiBold";
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 99%;
}

.Text-ComeBack {
  font-size: 1rem;
  font-family: "LatoSemiBold";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 95%;
  cursor: pointer;
}

.Text-ComeBack:hover {
  color: #84ac34;
}

.Title-Form-Register {
  font-size: 1.7rem;
  font-family: "GothamRoundedBold";
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  color: rgb(68, 68, 68);
}
