$green: #87ad36;

.radio-list{
    display: flex;
    gap: 1.5rem;

    &__button{
        width: 0.9375rem;
        height: 0.9375rem;
        border-radius: 100%;
        border: none;
        outline: none;
        border: 0.0625rem solid $green;
        transition: .2s all ease-in;
        position: relative;

        &:focus{
            outline: none;
        }

        &--active{
            background-color: $green;

            &::after{
                content: '';
                display: inline-block;
                position: absolute;
                width: 0.4375rem;
                height: 0.4375rem;
                background-color: #FFF;
                margin: auto;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                border-radius: 100%;

            }
        }
    }

    &__option{
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;

        
    }

    p{
        padding: 0;
    }

    .disabled{
        cursor: default !important;
    }
}