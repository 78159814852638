$green: #87ad36;


.alerts{
    &__buttons{
       display: flex;
       justify-content: center;
       margin: 1.25rem 0;
       gap: 0.75rem;
    }

    &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: auto;
            margin-bottom: 20px;
            max-width: 1280px;
    }

    &__button{
        background-color: $green;
        color: white;
        border: none;
        padding: 0.3125rem 1.25rem;
        border-radius: 0.25rem;
        transition: .4s all;

        &:focus {
            outline: none;
        }

        &:active{
            transform: scale(.97);
        }

    }

    &__title{
        // margin-top: 1.875rem;
        margin-bottom: 2.5rem 0;
        text-align: center;
        font-size: 1.5625rem;
    }

    &__content{
      max-width: 78.75rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

    }

    &__separator{
        width: 100% !important;
        background-color: $green;
    }

    .card{
        background-color: white;
        border-radius: 1.125rem;
        padding: 2.5rem;
        opacity: .6;
        transition: all .4s ease-in-out;

        &--active{
            opacity: 1;
        }
      
        &__body{
            margin-top: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__contact{
            display: flex;
            align-items: center;
            gap: 1.25rem;

        }

        &__header{
            display: flex;
            justify-content: space-between;
        }

        &__title{
            font-size: 1.25rem;
        }

        &__hours{
            width: 20rem;
            display: flex;
            gap: 1.25rem;
        }

    }

    .input{
        min-width: 12.5rem;
        position: relative;
        font-size: 0.875rem;

        &__box{
            width: 100%;
            outline: none;
            padding: 0.25rem 0.5rem;
            border: 0.0625rem solid gray;
            padding-left: 1.5rem;

            &::placeholder{
                font-size: 0.875rem;
                color: #acabab;
          
            }
        }

        &__icon{
            position: absolute;
            left: 0.375rem;
            top: 0.75rem;
            font-size: 0.875rem;
        }
    }

    input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

}

.flex{
    display: flex !important;
}