
$green: #87ad36;

.custom-control-label{
    &::before{
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid $green !important;
    }
}

.custom-control-input:checked~.custom-control-label::before{
    background-color: $green;
}
 
.custom-switch .custom-control-label::after {
    color: #FFF;
}