@import "../../styles/Shared/fonts.css";

.Body-Add {
  margin-bottom: 40rem;
  padding: 1rem;
}

.Card-Layout {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white;
  padding: 1rem;
  height: 60vh;
  margin: 1rem;
  overflow: auto;
}

.Card-Layout:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Delete-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #ab3822;
  background-color: #ab3822;
  padding: 0.3rem;
  width: 30%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "Gotham";
  font-size: 1.2rem;
  color: #fff;
}

.Div-Center-Button {
  margin-top: 1rem;
  text-align: center;
}

.Edit-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 30%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "Gotham";
  font-size: 1.2rem;
  color: #fff;
}

.Error-Message {
  font-size: 1.2rem;
  font-family: "LatoSemiBold";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(226, 86, 86);
  width: 95%;
}

.Equis {
  cursor: pointer;
}

.Img-Trash {
  cursor: pointer;
}

.List-Info {
  font-size: 1rem;
  font-family: "LatoSemiBold";
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(70, 67, 67);
}

.Load {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 1000;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #84ac34;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.7s linear infinite;
}

.Pop-Up-Emails {
  min-width: 50vw;
  min-height: 30vh;
  left: 25%;
  right: 25%;
  top: 30%;
  margin-right: 2vw;
  padding: 1rem;
  position: fixed;
  border-radius: 1rem;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 2px #9e9e9e;
  padding-bottom: 2rem;
}

.Pop-Up-Delete {
  min-width: 50vw;
  min-height: 30vh;
  left: 25%;
  right: 25%;
  top: 30%;
  margin-right: 2vw;
  padding: 1rem;
  position: fixed;
  border-radius: 1rem;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 2px #9e9e9e;
  padding-bottom: 2rem;
}

.Submit-Button-Single {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1.2rem;
  color: #fff;
}

.Text-Input {
  font-size: 1.2rem;
  font-family: "LatoSemiBold";
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 99%;
}

.Text-Error {
  font-size: 1rem;
  font-family: "LatoRegular";
  color: #ab3822;
}

.Title-List {
  font-size: 1rem;
  font-family: "Gotham";
}

.Title-Page {
  font-size: 1.2rem;
  font-family: "GothamRoundedBold";
}

.Title-Pop-Up-Error {
  font-family: "GothamRoundedBold";
  font-size: 1.5rem;
  color: #ab3822;
  margin-top: 1rem;
}

.Title-Pop-Up-Edit {
  font-family: "GothamRoundedBold";
  font-size: 1.1rem;
  color: black;
  margin-top: 0rem;
  padding: 0rem;
}

.Title-Pop-Up-Delete {
  font-family: "GothamRoundedBold";
  font-size: 1.1rem;
  color: #ab3822;
  margin-top: 0rem;
  padding: 0rem;
}

