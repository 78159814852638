.marketing {


    @media(max-width:1280px) {
        &__card {
            min-height: 52vh;
            height: max-content !important;
            margin-left: 0;
            margin-right: 0;

            &-value {
                font-size: 3.375rem;

            }

            &-title {
                font-size: 1.75rem;

            }
        }
    }
}