.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #84ac34;
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.Loading {
    left: 40vw;
    top: 40vh;
    position: fixed;
}

/* reusable */
.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.gap-2 {
    gap: 0.625rem;
}

.gap-4 {
    gap: 1.25rem;
}

.w-6 {
    width: 18.75rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.w-max {
    width: max-content;
}

.text-lg {
    font-size: 1.125rem;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.stacked-bar-chart {
  height: 90% !important;
}

.text-xs {
  font-size: 0.75rem;
}

#genericBody{
    width: 90%;
    margin: auto;
}

@media(max-width: 37.5rem){
    #genericBody{
       width: 95% !important;
       padding: 0.625rem;
    } 
}