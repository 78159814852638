@import "../../styles/Shared/fonts.css";

.Body-Admin-Resources-Group {
  margin-bottom: 8rem;
}

.Button-List {
  font-family: "LatoLight";
  font-size: 1rem;
  margin-right: 1rem;
}

.Button-Save {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  max-width: 11rem;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
}

.Card-Members {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.Container-Box-Resources {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  height: 41rem;
}

.Container-Box-Resources:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Box-Create {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  height: 20rem;
  overflow-y: scroll;
}

.Container-Box-Create:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Center {
  text-align: center;
}

.Input-Value {
  margin-top: 0.5rem;
  border-color: #9e9e9e;
  border-style: solid;
  border-image: none;
  border-width: 2px;
  color: rgb(112, 110, 110);
  padding-left: 0.7rem;
  width: 70%;
  max-width: 30rem;
  border-radius: 0.3rem;
  font-family: "LatoSemiBold";
  font-size: 1rem;
}

.Loader-Add {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 1000;
}

.Loader-Members {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 20%;
  z-index: 1000;
}

.Loader-Resources {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 70%;
  z-index: 1000;
}

.Text-Card {
  font-family: "LatoSemiBold";
  font-size: 1rem;
  color: #858585;
}

.Text-Black-Card {
  font-family: "Gotham";
  font-size: 1rem;
  color: #000000;
}


.Text-Member {
  font-family: "LatoLight";
  font-size: 1rem;
  margin-bottom: 0rem;
}

.Title-Resources-Card {
  font-family: "Gotham";
  font-size: 1.1rem;
  color: #000000;
  margin-bottom: 0rem;
}
