@import "../../styles/Shared/fonts.css";

.Body-Configuration {
  padding: 1rem;
  margin-bottom: 10rem;
}

.Button-Success-Load-More {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
  max-width: 200px;
}

.Camera {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
  padding: 0.5rem;
  border-color: #dae0e5;
}

.Camera:hover,
.Camera:active {
  background-color: #dae0e5;
}

.Container-Box {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
  min-height: 14.5rem;
}

.Container-Box:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Options{
  margin-bottom: 1rem;
}

.Div-Justify {
  text-align: justify;
}

.Div-Center {
  text-align: center;
  margin-top: 1.5rem;
}

.Div-Center-Title {
  text-align: center;
}

.Dropdown-Button {
  font-family: "LatoSemiBold";
  border-color: #9e9e9e;
}

.icon-Option{
  width: 1.8rem;
  display: inline;
}

.Input-Block {
  width: 5rem;
  border-color: #9e9e9e;
  border-radius: 0.5rem;
  border-style: solid;
  border-image: none;
  border-width: 2px 2px 2px;
  font-family: "GothamRoundedBold";
  font-size: 1.1rem;
  color: #9e9e9e;
  text-align: center;
}

.Input-Block:focus {
  border-color: #84ac34;
  outline: thick;
  color: rgb(68, 68, 68);
}

.Item-Dropdown {
  font-size: 1rem;
  font-family: "Gotham";
  margin-top: 0.5rem;
}

.Item-Dropdown:hover {
  background-color: #84ac34;
}

.label-Option{
  font-size: 1rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
  margin-bottom: 0.2rem;
  cursor: pointer;
  display: inline;
}

.label-Option-Dashboard{
  font-size: 0.9rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
  margin-bottom: 0.2rem;
  cursor: pointer;
  display: inline;
}

.Label-Text {
  font-size: 1.1rem;
  font-family: "GothamRoundedBold";
  color: rgb(68, 68, 68);
  margin-bottom: 0rem;
}

.More-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
  max-width: 200px;
}

.Submit-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1.2rem;
  color: #fff;
  max-width: 240px;
}

.Text-Alert {
  font-size: 1.1rem;
  font-family: "LatoBold";
}

.Title-Text-Configuration {
  font-size: 1rem;
  font-family: "LatoBold";
  color: rgb(206, 63, 63);
  margin-bottom: 0rem;
  margin-right: 0.5rem;
}

.Title-Black-Configuration {
  font-size: 1.1rem;
  font-family: "Gotham";
  color: rgb(68, 68, 68);
  margin-bottom: 0rem;
  margin-right: 0.5rem;
}

.Title-Configuration {
  font-size: 1.1rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-right: 0.5rem;
}

.Title-Secondary {
  font-size: 1.1rem;
  font-family: "LatoBold";
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-right: 1rem;
}

.Title-Secondary-List {
  font-size: 1.1rem;
  font-family: "LatoBold";
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-right: 1rem;
}

.Title-Dropdown {
  background-color: #84ac34;
  border-color: #84ac34;
  font-size: 1rem;
  font-family: "Gotham";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.Title-Alert {
  font-size: 1.4rem;
  font-family: "GothamRoundedBold";
}

.Title-Button-Alert {
  font-size: 1.1rem;
  font-family: "GothamRoundedMedium";
}

.Title-Window {
  font-size: 1.1rem;
  font-family: "GothamRoundedMedium";
}