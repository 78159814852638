  @font-face {
    font-family: Gotham;
    src: url("../../fonts/gotham/gotham.otf") format("opentype");
    font-display: block;
  }
  
  @font-face {
    font-family: GothamRoundedBold;
    src: url("../../fonts/gotham/GothamRounded-Bold.otf") format("opentype");
    font-display: block;
  }

  @font-face {
    font-family: GothamRoundedMedium;
    src: url("../../fonts/gotham/GothamRounded-Medium.otf") format("opentype");
    font-display: block;
  }

  @font-face {
    font-family: LatoLight;
    src: url("../../fonts/lato/Lato-Light.ttf") format("truetype");
    font-display: block;
  }
  
  @font-face {
    font-family: LatoBlack;
    src: url("../../fonts/lato/Lato-Black.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: LatoBold;
    src: url("../../fonts/lato/Lato-Bold.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: LatoMedium;
    src: url("../../fonts/lato/Lato-Medium.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: LatoRegular;
    src: url("../../fonts/lato/Lato-Semibold.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: LatoSemibold;
    src: url("../../fonts/lato/Lato-Semibold.ttf") format("truetype");
    font-display: block;
  }