.phone-input{
    width: 12.5rem;
    border-radius: 0 !important;

    >input{
        width: 100% !important;
        border-radius: 0 !important;
        border: 0.0625rem solid gray !important;
        height: 1.9375rem !important;

        &:focus{
            box-shadow: none !important;
        }
    }

    .flag-dropdown{
        border: 0.0625rem solid gray !important;
    }
}