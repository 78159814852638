@import "../../styles/Shared/fonts.css";

.Body-Events {
  padding: 1rem;
  margin-bottom: 26rem;
}

.Button-Update {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 100%;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
  max-width: 12rem;
}

.Button-More-Events {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 100%;
  margin-bottom: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1rem;
  color: #fff;
  max-width: 12rem;
}

.Button-Load-Event {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 100%;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
  max-width: 12rem;
}

.Cameras-Body {
  margin: 1rem;
}

.Card-Title-Element {
  font-family: "GothamRoundedBold";
  font-size: 1.1rem;
  color: #84ac34;
}

.Card-Text-Element {
  font-family: "LatoSemiBold";
  font-size: 0.9rem;
  color: rgb(70, 70, 70);
}

.Card-Title-Event {
  font-family: "Gotham";
  font-size: 0.9rem;
  color: rgb(63, 55, 55);
  margin-right: 0.5rem;
}

.Card-Event {
  margin: 0.8rem;
  width: 16rem;
}

.Div-Center-Image {
  text-align: center;
  margin-bottom: 1rem;
}

.Div-Center {
  text-align: center;
}

.Div-Left {
  text-align: left;
}

.Head-Modal{
  background-color: #84ac34;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; 
}

.icon-chat{
  display: inline-block;
  padding-bottom: 0.5rem;
}

.Name-Camera {
  font-size: 1.2rem;
  font-family: "GothamRoundedBold";
  color: rgb(68, 68, 68);
  margin-left: 0.4rem;
}

.Pop-Up {
  min-width: 60%;
  min-height: 40vh;
  margin-right: 2vw;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  width: 100%;
  border-radius: 1rem;
  z-index: 2;
  background-color: #f8f9fa;
  box-shadow: 1px 2px 3px 2px #9e9e9e;
}

.Title-Comment{
  font-weight: bold;
  font-size: 25px;
  display: inline-block;
  text-align: left;
}

.Title-Name-Camera {
  font-size: 1.2rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
}
