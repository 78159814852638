.calendar{

    @media(max-width:37.5rem){
        &__title{
            font-size: 1.25rem;
            text-align: center;
        }

      .fc-header-toolbar {  
           display: flex;
           flex-direction: column;
           gap: 0.625rem;
        }

        .fc-toolbar-title{
            font-size: 1.25rem;
        }

        .fc-event-title{
            font-size: 0.625rem;
            margin-top: 0.125rem;
        }

        .fc-button-group{
            button,.fc-today-button{
                font-size: 14px;
                padding: 0.25rem !important;
            }

        }

        .fc-today-button{
                font-size: 14px;
                padding: 0.25rem !important;
        }
    }
}

.charts-modal{
    @media(max-width:37.5rem){

        &__content{
            padding: 0;
        }

        .charts-filters {
            flex-direction: column;
            gap: 0.625rem;
        }

        .multiple-select{
            max-width: 100%;
        }

        .filter-button{
            margin: auto;
        }

        .charts-content{
            flex-direction: column;
        }

        .capacity-cards{
            flex-direction: column;

            .capacity-card{
                min-width: 17.5rem;
                width: 100%;
            }
        }

        .summary-section__content{
            flex-direction: column;

            .recharts-responsive-container, .recharts-wrapper,.recharts-surface,.recharts-legend-wrapper{
                width: 17.5rem !important;
                min-width: 17.5rem !important;
                max-width: 17.5rem !important;
                padding: 0 !important;
            }
    
            .recharts-responsive-container,.recharts-surfaces,.recharts-wrapper{
                height: 11.25rem !important;
    
            }
            .recharts-responsive-container{
               margin-bottom: 1.25rem;
            }
        }

    }

}
