@import "../../styles/Shared/fonts.css";

.Container-Remember {
  margin-left: 0rem;
  margin-right: 0rem;
  text-align: center;
  padding: 0rem;
  margin-bottom: 20%;
}

.Input-Email {
  margin-top: 0.5rem;
  border-color: #9e9e9e;
  border-style: solid;
  border-image: none;
  border-width: 0 0 2px;
  color: rgb(112, 110, 110);
  padding-left: 0.7rem;
  width: 100%;
}

.Input-Email:focus {
  border-color: #84ac34;
  outline: thick;
}

.Logo-Remember {
  margin-top: 20%;
  margin-bottom: 2rem;
  cursor: pointer;
  max-width: 300px;
}

.Submit-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1.2rem;
  color: #fff;
  max-width: 240px;
}

.Text-Email {
  font-size: 1.2rem;
  font-family: "LatoSemiBold";
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 99%;
  text-align: left;
}

.Text-ComeBack {
  font-size: 1rem;
  font-family: "LatoSemiBold";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
  width: 95%;
  cursor: pointer;
}

.Text-ComeBack:hover {
  color: #84ac34;
}

.Title-Form-Remember {
  font-size: 1.7rem;
  font-family: "GothamRoundedBold";
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  color: rgb(68, 68, 68);
}
