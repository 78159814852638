:root {
    --green: #87ad36;
    --red: #c0223f;
    --yellow:#e1b41b;
    --gray:#606060;
}

/* CALENDAR */
/* calendar buttons */
.fc-button-primary {
    background-color: var(--green) !important;
    border: 0.0625rem solid var(--green) !important;
}

/* current day box */
.fc-day-today {
    background-color: var(--green) !important;
    color: white;
}

.close-button {
    background-color: var(--red) !important;
    border: 0.0625rem solid transparent;
}

.save-button {
    background-color: var(--green) !important;
    border: 0.0625rem solid transparent;
}

.filter-button {
    width: 6.25rem !important;
    height: 2.375rem !important;
    background: var(--gray);
    border: none;
}

.filter-button:hover {
    background: var(--green);
    border: none;
    box-shadow: 0;
}

/* CHARTS */
/* chart content component */
.charts-modal .modal-content {
    padding: 0.9375rem 1.25rem;
    min-width: 100% !important;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 0.75rem;
}

.charts-modal .charts-title {
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold;
    margin-bottom: 1.875rem;
}

.charts-modal button {
    outline: none;
}

.modal-content::-webkit-scrollbar {
    display: none;
}

.charts-filters {
    display: flex;
    gap: 3.125rem;
    margin-bottom: 0.625rem;
}

.charts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin: 2.5rem 0;
}

.multiple-select {
    width: 37.5rem !important;
}

.time-select {
    width: 12.5rem;
}

.capacity-cards {
    margin-top: 1.25rem;
    display: flex;
    gap: 0.625rem;
    justify-content: center;
    align-items: center;
}

.capacity-card {
    background: linear-gradient(to bottom, #EFEFEF, #DCDCDC);
    border-radius: 1.25rem;
    padding: 1rem;
    width: 19.375rem;
    transition: 0.4s all;
    height: max-content;
}

.total-value {
    font-size: 1.25rem;
    font-family: "LatoBold";
    text-align: center;
}

.capacity-card p {
    transition: 0.4s all;
    text-align: center;
}

.capacity-card:hover {
    background: hsl(207, 55%, 23%);
    color: white;
}

.capacity-card:hover p {
    color: white;
}

.capacity-date {
    font-size: 0.8125rem;
    font-family: "LatoSemiBold";
    color: rgb(68, 68, 68);
    margin-bottom: 0.2rem;
}

.capacity-value {
    font-size: 0.8125rem;
    font-family: "LatoBold";
    color: rgb(68, 68, 68);
}

.charts-content {
    display: flex;
    gap: 1.875rem;
    justify-content: center;
}

.characterization-section, .summary-section {
    padding: 1rem;
    border: 0.0625rem solid rgb(160, 160, 160);
    border-radius: 0.375rem;
    position: relative;
}

.characterization-section {
    height: max-content;
    min-width: 18.75rem;
}

.summary-section {
    flex: 1;
    max-width: 46rem;
}

.cipher-texts {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.cipher-label {
    font-size: 12px;
    line-height: 0.875rem;
}

.cipher-text {
    text-align: center;
    border-bottom: 0.0625rem solid #87ad36;
    font-size: 12px;
}

.cipher-value {
    margin-top: 0.75rem;
    font-family: "LatoBold";
    line-height: 0.875rem;
}

.people-icon {
    color: var(--green);
    margin-left: 10px;
    font-size: 30px;
}

.dog-icon {
    color: var(--green);
    margin-left: 0.25rem;
    font-size: 0.875rem;
}
