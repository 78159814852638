@import "fonts.css";

.Div-Error {
  text-align: center;
  margin-top: 10%;
}

.Text-Error-404 {
  font-size: 1.5rem;
  font-family: "Gotham";
  color: rgb(68, 68, 68);
  margin-top: 3rem;
  text-align: center;
}
