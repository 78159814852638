$green: #87ad36;
$red: #c0223f;
$gray: #606060;

.historical {
    &__title {
        text-align: center;
        font-size: 1.375rem;
        margin: 1.25rem 0 1.25rem 0;
    }

    &__filters {
        display: flex;
        gap: 1.25rem;
        height: 2.375rem;
        margin-bottom: 1.875rem;
        color: #878686;
    }

    &__pie {
        position: relative;
    }

    &__pie-title {
        color: $gray;
        font-size: 18px;
        position: absolute;
        top: 1.25rem;
        right: -3.125rem;
    }

    .date-picker {
        max-width: 13.75rem;
    }

    &__select {
        &--long {
            min-width: 17.5rem;
            width: 17.5rem;
            max-width: 25rem;
        }

        &--short {
            width: 7.5rem;
        }
    }

    .css-3w2yfm-ValueContainer, .css-1dyz3mf {
        height: 1.875rem !important;
    }

    &__chart-container {
        background-color: white;
        padding: 1.25rem;
        border-radius: 0.75rem;
        margin: 1.875rem auto;
        position: relative;
    }

    &__total {
        width: max-content;
        margin: auto;
        color: $gray;

        &--value {
            text-align: center;
            font-size: 1.375rem;
            font-weight: bold;
            text-decoration: underline;
            line-height: 1.25rem;
            margin-bottom: 0.25rem;
        }

        &--text {
            font-size: 0.875rem;
        }
    }

    &__resume {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3.75rem;
    }

    &__peaks {
        display: flex;
        gap: 2.5rem;
        // flex-direction: column;
    }

    &__value {
        font-size: 2.25rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0.25rem;
        text-decoration: underline;

        &--maximum {
            color: $green;
            font-size: 44px;
        }

        &--minimum {
            color: $red;
            margin-top: 0.75rem;
        }
    }

    &__date {
        font-size: 0.875rem;
    }

    &__pie-chart {
        .recharts-default-legend {
            position: relative;
            top: 50px;
            right: -10px;
        }
    }

    .bold {
        font-weight: bold;
    }
}

.monthly-summary {
    width: 12.5rem;
    color: $gray;

    &__value {
        font-size: 26px;
        font-weight: bold;
        text-align: center;

        &--maximum {
            color: $green;
        }

        &--minimum {
            color: $red;
        }
    }

    &__month {
        text-align: center;
    }
}

.monthly-history {
    margin-top: 50px;
    margin-bottom: 3.125rem;

    &__values {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 1.25rem;
    }
}
