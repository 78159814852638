@import "fonts.css";

.Footer-Bar {
    background-color: #F8F8F8;
    border-top: 1px solid #E7E7E7;
    text-align: center;
    padding: 0.1rem;
    position: relative;
    left: 0rem;
    bottom: 0rem;
    width: 100%;
}

.Footer-Text {
  font-size: 1rem;
  font-family: "Gotham";
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  color: rgb(70, 67, 67);
}

.Icon-Menu-Footer {
  width: 6rem;
  margin: 0rem 0.7rem;
}