@import "../styles/Shared/fonts.css";
 
/* g{
  transform: translate(0, -2);
  fill: none;
  font-size: 10;
  font-family: sans-serif;
  text-anchor: middle;
} */
/* g{
  display: block;
}
g rect{
  display: block;
  width: 50px
} */

.Body-Home {
  padding: 1rem;
  margin-bottom: 10rem;
}

.Card-insight{
  text-align: center;
  background: linear-gradient(to bottom, #EFEFEF, #DCDCDC);
  border-radius: 20px;
}

.Card-insight:hover{
  background: #1A3D5A;
  color: white;
}

.Card-insight:hover label{
  color: white;
}

.Container-Box-Home {
  background-color: white;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  padding: 1rem;
  margin-top: 1rem;
  /* min-height: 14.5rem; */
}

.Container-Box-Home:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Insight {
  border-style: solid;
  border-width: 0.2rem;
  border-color: #606060;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.Container-Layout-Home {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white; 
  padding: 1rem;
  height: 55vh;
  overflow: hidden;
  margin: 1rem;
}

.Container-Layout-Home:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Options{
  margin-bottom: 1rem;
}

#ContainerProgressBar{
  text-align: center;
  position: absolute;
  left: 25%;
  top: 40%;
  z-index: 1000;
  /* position: fixed;
  float: right;
  bottom: 0;
  margin-bottom: 30px;
  height: 53px;
  right: 0;
  margin-right: 10px;
  width: 50%;
  background-color: white;
  z-index: 51;
  border-radius: 5px;
  border: 1px solid black; */
}

.Insight-Home{
  margin: auto; 
  font-family: "Gotham";
  font-size: 1.5rem;
  color: #606060;
}

.responsive {
  max-width: 100%;
  height: auto;

}

.label-Option-insight{
  font-size: 0.9rem;
  font-family: "LatoSemiBold";
  color: rgb(68, 68, 68);
  margin-bottom: 0.2rem;
  cursor: pointer;
  display: inline;
}

.ProgressText{
  font-family: "Gotham";
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #606060;
  text-align: center;
  display: grid;
}

.Title-greeting {
  font-size: 1.4rem;
  font-family: "GothamRoundedMedium";
}

.Title-right-column {
  font-size: 1.1rem;
  font-family: "Gotham";
  color: rgb(68, 68, 68);
  margin-bottom: 0rem;
  /*margin-right: 0.5rem;*/
}

.characterization-chart{
  flex: 1;
  max-width: 93.75rem;
  background-color: white;
  margin-top: 2.5rem;
  border-radius: 1.25rem;
  padding: 1.25rem;
  height: 55vh;
}

.characterization-title{
  font-size: 1rem;
  margin-bottom: 1.25rem;
}

.invisible{
  visibility: hidden;
  position: absolute;
}