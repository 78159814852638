.chat-button {
    position: sticky;
    display: flex;
    flex-direction: column;
    z-index: 100 !important;
    width: max-content;
    margin-left: auto;
    margin-right: 2.5rem;
    bottom: 2.5rem;
    border: none;
    background-color: transparent;
    padding: 0;
    
    &:focus{
        outline: none;
    }
    
    &__icon {
        width: 3.75rem;
        cursor: pointer;
    }

    &__text {
        color: #87ad36;
        font-weight: 700;
        position: relative;
        bottom: 0.625rem;
    }
}
