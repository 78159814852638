@import "../../styles/Shared/fonts.css";

.Body-Points-Selection {
    margin-bottom: 15rem;
}

.Button-Reload {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #9e9e9e;
  background-color: #9e9e9e;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  width: 10%;
  min-width: 10rem;
  cursor: pointer;
}

.Button-Save {
    border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  width: 10%;
  min-width: 10rem;
}

.Input-Zone {
  margin-top: 0.5rem;
  border-color: #9e9e9e;
  border-style: solid;
  border-image: none;
  border-width: 2px;
  color: rgb(112, 110, 110);
  padding-left: 0.7rem;
  width: 20rem;
}

