.input-group {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.react-datepicker-wrapper input {
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 0.125rem);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
