.Body-Form {
  margin-bottom: 10rem;
}

.Card-Layout-Form-Symptoms {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin: 1rem;
  overflow: hidden;
  background-image: url("../../imgs/bg-symptoms.png");
  background-position: 50vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.Card-Layout-Form-Symptoms:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Card-Layout-Form-Covid {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin: 1rem;
  overflow: hidden;
  background-image: url("../../imgs/bg-covid.png");
  background-position: 70vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.Card-Layout-Form-Covid:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Check {
  cursor: pointer;
}

.Check-Box {
  margin-right: 0.5rem;
  width: 1rem;
  box-sizing: content-box;
  box-shadow: 0 1px 1px 1px #84ac34;
  border-radius: 1rem;
}

.Div-Center-Title {
  text-align: center;
  padding: 1rem;
}

.Pop-Up-Success {
  min-width: 50vw;
  min-height: 30vh;
  left: 25%;
  right: 25%;
  top: 30%;
  margin-right: 2vw;
  padding: 2rem;
  position: fixed;
  border-radius: 1rem;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 1px 2px 3px 2px #9e9e9e;
  text-align: center;
}

.Submit-Button-Form {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 40%;
  margin-bottom: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1.2rem;
  color: #fff;
}

.Text-Advice {
  font-family: "LatoRegular";
  font-size: 0.8rem;
  color: #455e13;
}

.Text-Symptom {
  font-family: "LatoRegular";
  font-size: 1rem;
  margin-left: 0.5rem;
}

.Title-Question {
  font-family: "GothamRoundedMedium";
  margin-bottom: 0rem;
}

.Title-Form-Symptoms {
  font-family: "GothamRoundedBold";
  margin-top: 1rem;
  font-size: 1.5rem;
}

.Title-Pop-Up {
  font-family: "GothamRoundedBold";
  font-size: 1.5rem;
  color: #84ac34;
  margin-top: 1rem;
}
