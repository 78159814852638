@import "../../styles/Shared/fonts.css";

.Body-Dashboard {
  padding: 1rem;
  margin-bottom: 0rem;
  margin: 0rem;
}

.Button-See-Event {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.2rem;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  max-width: 12rem;
}

.Button-Apply-Filter {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  width: 10%;
  min-width: 10rem;
}
.Button-Close-Modal {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #dc3545;
  background-color: #dc3545;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  width: 10%;
  min-width: 10rem;
}

.Button-Delete-Filter {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #9e9e9e;
  background-color: #9e9e9e;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-family: "Gotham";
  font-size: 0.8rem;
  color: #fff;
  width: 10%;
  min-width: 10rem;
  cursor: pointer;
}

.Button-Change-Dash {
  background-color: #dae0e5;
  width: 10rem;
  margin-right: 0.5rem;
  font-family: "Gotham";
  font-size: 0.9rem;
}

.Camera-Button {
  font-family: "Gotham";
  font-size: 0.9rem;
  border-color: #dae0e5;
  margin-left: 0.7rem;
}

.Capacity {
  font-family: "Gotham";
  font-size: 4rem;
  color: #84ac34;
}

.Card {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.3rem;
  padding: 0.5rem;
  overflow: hidden;
  margin: 0.1rem;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
  background-color: #fff;
}

.Card-Body {
  padding: 0.5rem;
}

.Card-Event-Title {
  font-size: 0.8rem;
  font-family: "GothamRoundedBold";
  color: rgb(70, 67, 67);
}

.Card-Event-Text {
  font-size: 0.9rem;
  font-family: "LatoSemiBold";
  color: rgb(70, 67, 67);
}

.Card-Last-Event {
  margin: 1rem;
  width: 18rem;
}

.Container {
  padding: 0rem;
}

.Container-Layout {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 13px;
  background-color: white; 
  padding: 1rem;
  height: 52vh;
  overflow: hidden;
  margin: 1rem;
}

.Container-Layout:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Layout-Capacity {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  padding: 1rem;
  height: 68vh;
  overflow: hidden;
  margin: 1rem;
}

.Container-Layout-Capacity:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.Container-Layout-Zones {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  padding: 1rem;
  height: 60vh;
  overflow: hidden;
  margin: 1rem;
}

.Container-Layout-Zones:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}



.Container-Layout-Events {
  border-style: solid;
  border-width: 0.1rem;
  border-color: #99999973;
  border-radius: 0.1rem;
  padding: 0.5rem;
  height: 52vh;
  overflow: auto;
  margin: 1rem;
}



.Container-Layout-Events:hover {
  box-shadow: 0 1px 3px 1px #9e9e9e;
}

.contain_update,
.contain_update-none {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Date-Picker {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  font-family: "Gotham";
  font-size: 1rem;
  height: 2.3rem;
  width: 12rem;
  margin-right: 0.5rem;
}

.Date-Picker-day {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  font-family: "Gotham";
  font-size: 1rem;
  height: 2.3rem;
  width: 7rem;
  margin-right: 0.5rem;
}

.Div-Profile {
  background-color: #f8f9fa;
  border-color: #dae0e5;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  min-height: 160vh;
  padding: 1rem;
}

.Div-Updates {
  background-color: #f8f9fa;
  border-color: #dae0e5;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  min-height: 120vh;
  padding: 1rem;
}

.Div-Center-Chart {
  text-align: center;
}

.Div-Center-Events {
  text-align: justify;
}

.Div-Date {
  margin-left: 2rem;
}

.Div-Text-Left {
  text-align: left;
}

.Event-Card {
  margin: 0.3rem;
  font-size: 1rem;
  font-family: "GothamRoundedBold";
  color: rgb(68, 68, 68);
  padding: 0.3rem;
  border-color: #dae0e5;
  max-width: 16rem;
}

.Link-Update {
  color: rgb(68, 68, 68);
}
.Link-Update:hover {
  color: rgb(68, 68, 68);
}

.Line-Separator {
  width: 100%;
  border-color: #9e9e9e;
  border-style: solid;
  border-width: 0.02rem;
  margin-bottom: 0.5rem;
}

.Line-Separator-Menu {
  width: 100%;
  border-bottom: 1px solid #9e9e9e;
  margin-bottom: 0.5rem;
}

.Load {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 1000;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #84ac34;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#noZonesText{
  font-size: 1rem;
  font-family: "Gotham";
  color:#dc3545;
}

p,
a {
  margin: 0;
}

.Table {
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.tabulator-col{
  text-align: center;
}

.Text-Greeting {
  font-size: 1rem;
  font-family: "Gotham";
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(70, 67, 67);
}

.Title-Aforo {
  font-family: "Gotham";
  font-size: 2rem;
}

.Title-Chart {
  font-size: 1.2rem;
  font-family: "Gotham";
  color: rgb(70, 67, 67);
}

.Title-Chart-Left {
  font-size: 1rem;
  font-family: "Gotham";
  color: rgb(70, 67, 67);
  text-align: left;
  margin-bottom: 0rem;
}

.Title-Update {
  margin-bottom: 0rem;
  font-size: 0.9rem;
  font-family: "LatoSemiBold";
  color: rgb(70, 67, 67);
  text-align: left;
}

.Title-Message {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-family: "Gotham";
  color: rgb(70, 67, 67);
}

.Text-Message {
  font-size: 1rem;
  font-family: "LatoBold";
  color: rgb(70, 67, 67);
}

.Title-Page {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-family: "GothamRoundedBold";
  color: rgb(70, 67, 67);
}

.Text-Update {
  font-size: 0.8rem;
  font-family: "LatoSemiBold";
  color: rgb(70, 67, 67);
  margin-bottom: 1rem;
}

.Text-Update:hover {
  text-decoration: none;
}
#loadingImg {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
#loadingImg img {
  max-width: 100%;
  height: 80%;
}
