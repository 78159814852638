$green: #87ad36;
$gray: #606060;

.profile-card {
    z-index: 1;
    position: absolute;
    right: 3.75rem;
    background-color: #ffffff;
    transition: visibility 0.1s linear, opacity 0.1s linear;
    opacity: 1;
    border-radius: 0.375rem;
    max-width: 16.875rem;
    padding: 1.875rem 0;

    &__header{
      display: flex;
      align-items: center;
      gap: 0.625rem;
      padding: 0 0.625rem;
    }
  
    &__initial{
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 100%;
      background-color: $gray;
      color: white;
      font-weight: bold;
       font-size: 1.25rem;
       display: flex;
       justify-content: center;
       align-items: center;
       text-transform: uppercase;
    }
  
    &__user{
      font-size: 0.875rem;
      padding: 0;
      font-weight: bold;
      line-height: 0.875rem;
  
    }
  
    &__email{
      font-size: 0.75rem;
      padding: 0;
      line-height: 0.75rem;
      margin: 0.375rem 0;
    }
  
    &__brand{
      font-size: 0.75rem;
      color: $green;
      padding: 0;
      line-height: 0.75rem;
      font-weight: bold;
    }
  
    &__text{
       display: flex;
       align-items: center;
       justify-content: center;
       gap: 0.25rem;
       width: max-content;
       margin: auto;
  
     >i{
        cursor: pointer;
        transition: .4s all;
     }
  
       >p{
           cursor: pointer;
           transition: .4s all;
          &:hover{
              color: $green;
          }
  
          &:hover ~ i{
              color: $green;
          }
       }
    }
  
    >hr{
      width: 100%;
      height: 0.0063rem;
      background-color: #dedddd;
    }
  
    >button{
      border: none;
      background-color: transparent;
      &:focus{
          outline: none;
      }
    }
  
  
  }

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 2.5rem;
  }

  .line {
    width: 90%;
  }