.css-3w2yfm-ValueContainer, .css-1dyz3mf {
    max-height: 7.8125rem !important;
    overflow-y: scroll !important;
}

.css-3w2yfm-ValueContainer::-webkit-scrollbar {
    display: none;
}

.css-1dyz3mf::-webkit-scrollbar {
    display: none;
}
