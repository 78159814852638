@import "../../styles/Shared/fonts.css";

.Body-Cameras {
  padding: 1rem;
  margin-bottom: 30rem;
}

.Camera-Element {
  margin: 1rem;
  font-size: 1.1rem;
  font-family: "Gotham";
  color: rgb(68, 68, 68);
  padding: 0.9rem;
  border-color: #dae0e5;
  width: 20rem;
}

.Content-Popover {
  padding: 0.6rem;
}

.Div-Center {
  text-align: center;
}

.Events-Button {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 100%;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: 1rem;
  color: #fff;
  max-width: 12rem;
}

.Image {
  margin-bottom: 1rem;
}

.page-item.active .page-link {
  font-family: "GothamRoundedBold";
  font-size: 1rem;
  z-index: 2;
  color: #fff;
  background-color: #84ac34;
  border-color: #84ac34;
}

.page-link {
  font-family: "Gotham";
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #84ac34;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  font-family: "GothamRoundedBold";
  z-index: 2;
  color: #28a745;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.Text-Content {
  font-size: 0.9rem;
  font-family: "LatoSemiBold";
  color: rgb(70, 67, 67);
  margin-top: 0rem;
}

.Title-Card {
  font-size: 1.2rem;
  font-family: "GothamRoundedBold";
  color: rgb(68, 68, 68);
}

.Title-Content {
  font-size: 1rem;
  font-family: "Gotham";
  color: rgb(68, 68, 68);
  margin-bottom: 0rem;
  margin-right: 0.3rem;
}

