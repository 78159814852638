.chart-title {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
}

.recharts-legend-item-text {
    font-size: 0.75rem;
    cursor: pointer;
}

.recharts-legend-wrapper {
    bottom: -0.3125rem !important;
}

.pie-chart, .pie-chart-cell {
    outline: none;
    cursor: pointer;
    transition: .4s all;
}

.pie-chart-cell:hover {
    opacity: .9;
}

.chart {
    padding: 1.875rem;
    background-color: white;
    padding-bottom: 0.625rem !important;
    border-radius: 0.625rem;
    width: max-content !important;
    width: 100% !important;
    border: 0.0625rem solid #99999973;
}

.recharts-responsive-container, .recharts-wrapper {
    margin: auto;
}

.custom-tooltip{
    background-color: white;
    border-radius: 0.125rem;
    font-size: 0.875rem;
    padding: 0.375rem;
  }
