.Body-Dashboard-Symptoms {
    margin-bottom: 1rem;
    padding: 1rem;
}

.Center-Title {
  text-align: center;
}

.Title-Dashboard {
  font-family: "GothamRoundedBold";
  font-size: 1.3rem;
  color: rgb(70, 67, 67);
}

.Container-Layout-Symptoms {
    border-style: solid;
    border-width: 0.1rem;
    border-color: #99999973;
    border-radius: 13px;
    background-color: white;
    padding: 1rem;
    height: 52vh;
    overflow: hidden;
    margin: 1rem;
  }
  
  .Container-Layout-Symptoms:hover {
    box-shadow: 0 1px 3px 1px #9e9e9e;
  }
  