
$green: #87ad36;
$gray: #606060;

.chatbot {
    position: sticky;
    z-index: 100 !important;
    width: max-content;
    margin-left: auto;
    margin-right: 2.5rem;
    bottom: 2.5rem;
    width: 300px;
    background-color: white;
    border-radius: 10px 10px 0.625rem 0.625rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 400px;
    transition: .2s all ease-in-out;
    
    &--big{
        width: 100%;
        max-width: 58.75rem;
        min-height: 43.75rem;

        .chatbot__header{
            border-radius: 0;
        }

    }


    &__sidebar {
        width: 28%;
        padding: 25px;
        background-color: rgba(135, 173, 54, .15);
        overflow-y: auto;
        flex-direction: column;
        display: flex;

        &-subtitle{
            font-size: 18px;
        }

        &-content{
            flex: 1;
        }

        &-questions{
            padding-left: 10px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            transition: .3s all;
            
            >p{
                color: #606060;
                opacity: .4;
                text-decoration: underline;
                cursor: pointer;
    
                &:hover{
                    opacity: 1;
                }
            }
        }

        &-buttons{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            >button{
                border: none;
                background-color: white;
                padding: 6px 20px;
                border-radius: 14px;
                font-size: 14px;
                transition: .3s all;
                opacity: .4;

                &:hover{
                    opacity: 1;
                }

            }
        }
    }

    &__content{
        flex: 1;
        display: flex;
    }

    &__header{
       background-color: #87ad36;
       padding: 0.25rem 0.5rem;
       display: flex;
       justify-content: space-between;

       >img{
        width: 30px;
       }
    }

    &__icons{
        color: white;
        display: flex;
        align-items: center;
        gap: 10px;

        >i{
            cursor: pointer;
        }
    }

    &__body{
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__plus{
        width: 1.25rem;
        height: 1.25rem;
        border: 0.0625rem solid gray;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        font-size: 0.875rem;
        margin-bottom: 0.9375rem;
      
    }

    &__writing-area{
     padding: 5px 8px;
     border: 0.0625rem solid gray;
     font-size: 14px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: gray;

    }

    &__messages{
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1;
    }

    &__message{
        display: flex;
        align-items: center;
        border: 1px solid gray;
        padding: 4px;
        justify-content: space-between;
        font-size: 14px;
        color: gray;

        >img{
            width: 20px;
        }
    }

    // components

    .reports{
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1;

        &__title{
            font-size: 22px;
        }

        &__actions{
            display: flex;
            justify-content: space-between;
        }

        &__dates{
            display: flex;
            gap: 10px;
        }

        &__download{
            padding: 6px 30px;
            border: 1px solid hsl(0, 0%, 80%);
            font-size: 14px;
            color: $gray;
            background-color: white;
            
        }

        &__subtitle{
            color: $green;
            font-size: 1.4375rem;
            font-weight: bold;
            text-align: center;
            margin: 0.625rem 0 0.3125rem 0;
        }

        &__text{
            text-align: center;
            color: $gray;
        }

        &__image{
            width: 80%;
            margin: auto;
            display: block;
        }
    }

    .fa-solid{
        transition: .3s all;
    }

    .active{
        opacity: 1;
    }

    button{
        border: none;
        &:focus{
            outline: none;
        }

        &:active{
            transform: scale(.96);
        }
    }
}
